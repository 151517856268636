




import Vue from 'vue';
import { IStyleContent } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-part-hyperlink',
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  computed: {
    style(): Partial<CSSStyleDeclaration> {
      const style: Partial<CSSStyleDeclaration> = {};
      if (this.part.color) {
        style.color = this.part.color;
      }
      return style;
    },
  },
  methods: {
    handleClick() {
      if (this.part.uen) {
        this.$router.push({ name: 'products-run', params: { uen: this.part.uen.toUpperCase() } });
        return;
      }
      if (this.part.path) {
        this.$router.push({ name: this.part.path.toLowerCase() }).catch(() => {});
      }
    },
  },
});
