


















import Vue from 'vue';
import { IStyleContent, ITherapyImage, StyleDeviceEnum, StyleHorizontalEnum, StyleSizeEnum, StyleVerticalEnum } from '@/scripts/store/modules/therapy/types';
import ElementPartGraphicContent from '@/views/products/components/elements-parts/element-part-graphic-content.vue';

export default Vue.extend({
  name: 'element-part-graphic',
  components: { ElementPartGraphicContent },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      imageSrc: null as string | null,
      imageAlt: null as string | null,
    };
  },
  computed: {
    deviceStyles() {
      return StyleDeviceEnum;
    },
    horizontalStyles() {
      return StyleHorizontalEnum;
    },
    sizeStyles() {
      return StyleSizeEnum;
    },
    shouldDisplay(): boolean {
      return this.part && (!this.part.device ||
        (this.part.device === StyleDeviceEnum.MOBILE && this.$vuetify.breakpoint.xsOnly) ||
        (this.part.device === StyleDeviceEnum.DESKTOP && this.$vuetify.breakpoint.smAndUp));
    },
    isTopBanner(): boolean {
      return this.part.size === StyleSizeEnum.XL && this.part.vertical === StyleVerticalEnum.TOP;
    },
  },
  mounted() {
    this.$log.debug('Displaying graphic', this.part);
    this.loaded = false;
    this.$store.dispatch('therapy/getTherapyImage', this.part.id ? this.part.id : this.part.content)
      .then((res: ITherapyImage) => {
        this.imageSrc = res?.image;
        this.imageAlt = res?.altText;
        this.loaded = true;
      });
  },
});
