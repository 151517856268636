var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-textarea", {
    staticClass: "assessment-notes no-underline pb-0 ba-0",
    attrs: {
      "row-height": "1",
      placeholder: _vm.$t("common.actions.add_situation"),
      maxlength: 2000,
      color: _vm.color,
      "auto-grow": ""
    },
    on: { input: _vm.debounceInput },
    model: {
      value: _vm.internalNotes,
      callback: function($$v) {
        _vm.internalNotes = $$v
      },
      expression: "internalNotes"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }