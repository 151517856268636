var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "input-wrapper-note px-2 my-2", attrs: { flat: "" } },
    [
      _c(
        "v-layout",
        { attrs: { "no-gutters": "" } },
        [
          !_vm.showButtonNote
            ? _c(
                "v-flex",
                {
                  staticClass: "title-wrapper ma-0 pa-0",
                  attrs: { cols: "12" }
                },
                [
                  _c("span", { staticClass: "starling-small-text" }, [
                    _c("strong", [_vm._v(_vm._s(_vm.noteTitle))])
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        [
          !_vm.showButtonNote && _vm.generalNotes.length
            ? _c(
                "div",
                [
                  _vm.isNativePlatform
                    ? _vm._l(_vm.generalNotes, function(note, index) {
                        return _c("input-note-native", {
                          key: index,
                          attrs: {
                            noteIndex: index,
                            loading: _vm.loadingNote,
                            note: note.note
                          },
                          on: { updated: _vm.onGeneralNoteUpdated }
                        })
                      })
                    : _vm._l(_vm.generalNotes, function(note, index) {
                        return _c("input-note-web", {
                          key: index,
                          attrs: {
                            noteIndex: index,
                            loading: _vm.loadingNote,
                            note: note.note
                          },
                          on: { updated: _vm.onGeneralNoteUpdated }
                        })
                      })
                ],
                2
              )
            : _vm._e(),
          !_vm.showButtonNote && _vm.generalNotes.length
            ? _c("horizontal-line-note", {
                attrs: { note: _vm.safeNote.note, noteType: _vm.safeNote.type }
              })
            : _vm._e(),
          !_vm.showButtonNote
            ? [
                _vm.isNativePlatform
                  ? [
                      _c("input-note-native", {
                        attrs: {
                          loading: _vm.loadingNote,
                          note: _vm.safeNote.note
                        },
                        on: { updated: _vm.onNoteUpdated }
                      })
                    ]
                  : [
                      _c("input-note-web", {
                        attrs: {
                          loading: _vm.loadingNote,
                          note: _vm.safeNote.note
                        },
                        on: { updated: _vm.onNoteUpdated }
                      })
                    ]
              ]
            : _vm._e(),
          _vm.showButtonNote
            ? _c("button-note", {
                attrs: { noteType: _vm.type },
                on: { addNote: _vm.onAddNote }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }