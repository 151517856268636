var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-textarea", {
    staticClass: "assessment-notes-native no-underline pb-0 ba-0",
    attrs: {
      "row-height": "1",
      placeholder: _vm.$t("common.actions.add_situation"),
      maxlength: 2000,
      color: _vm.color,
      "auto-grow": ""
    },
    on: { input: _vm.debounceInput },
    scopedSlots: _vm._u(
      [
        _vm.supported
          ? {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: _vm.recording
                          ? _vm.speaking
                            ? "success"
                            : "primary"
                          : null,
                        fab: "",
                        flat: "",
                        small: ""
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.toggleSpeechRecognition($event)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { medium: "" } }, [
                        _vm._v("\n        mic\n      ")
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    ),
    model: {
      value: _vm.internalNotes,
      callback: function($$v) {
        _vm.internalNotes = $$v
      },
      expression: "internalNotes"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }