var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      class: {
        "justify-start": _vm.justifyLeft,
        "justify-center": _vm.justifyCenter,
        "justify-end": _vm.justifyRight
      }
    },
    [
      _c(
        "v-flex",
        { class: { "half-size": _vm.halfSize }, attrs: { shrink: "" } },
        [
          _vm.part
            ? _c("p", {
                staticClass: "starling-explanatory text-left",
                style: _vm.style,
                domProps: { innerHTML: _vm._s(_vm.markup(_vm.part.content)) }
              })
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }