var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.part
    ? _c("h2", {
        staticClass: "starling-h2",
        style: _vm.style,
        domProps: { innerHTML: _vm._s(_vm.markup(_vm.part.content)) }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }