<template>
    <v-textarea v-model="internalNotes"
                class="assessment-notes no-underline pb-0 ba-0"
                row-height="1"
                :placeholder="$t('common.actions.add_situation')"
                :maxlength="2000"
                :color="color"
                auto-grow
                @input="debounceInput">
    </v-textarea>
  </template>

<script>
import _ from 'lodash';

export default {
  name: 'note-input',
  props: {
    note: String,
    color: String,
    loading: Boolean,
    noteIndex: {
      type: Number,
      defualt: null,
    },
  },
  data() {
    return {
      internalNotes: this.note,
      savedNotes: this.note,
      debounceInput: undefined,
    };
  },
  watch: {
    note(newNote) {
      this.internalNotes = newNote;
    },
  },
  created() {
    const that = this;
    this.debounceInput = _.debounce(() => {
      that.savedNotes = that.internalNotes;
      if (this.noteIndex !== null && !this.loading) {
        this.$emit('updated', this.savedNotes, this.noteIndex);
      } else if (!this.loading) {
        this.$emit('updated', this.savedNotes);
      }
    }, 1000);
  },
};
</script>

  <style scoped>
  .assessment-notes .v-input__append-inner {
    color: currentColor;
  }
.no-underline .v-input__slot::before,
.no-underline .v-input__slot::after {
  margin-bottom: 0px !important;
  display: none !important;
}
.v-messages{
  min-height: 0px !important;
}
.no-underline ::v-deep .v-input__slot {
  margin-bottom: 0px !important;
}
  </style>
